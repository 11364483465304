<script>
export default {
  name: 'Currency',
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    formattedValue() {
      if (Number.isNaN(parseFloat(this.value))) return this.value;
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(this.value);
    },
  },
};
</script>

<template lang="pug">
span {{ formattedValue }}
</template>
